(function($) {

    if (window.fd && window.fd.logging) {
        window.fd.logging = false;
    }

    var VTUploader = function(element) {

        var that = this, Queue = $({});

        this.el = element;
        this.uploader = this.el.find('.mediauploader');
        this.media = this.uploader.data('media');
        this.url = this.uploader.data('url');
        this.options = {
            iframe: {
                url: that.url
            },
            extraHeaders: true,
            multiple: this.uploader.data('multiple'),
            allowed: this.uploader.data('allowed'),
            maxsize: this.uploader.data('maxsize')
        };
        this.zone = new FileDrop(this.uploader.attr('id'), this.options);
        this.progress = $(this.el.data('progress-bar'));
        this.progressbar = this.progress.find('.uploader-progress');
        this.currentbox = this.progress.find('.uploader-current');
        this.manager = $(this.el.data('media-manager'));
        this.messagebox = $(this.el.data('message-box'));
        this.chunkSize = 1048576;
        this.totalfile = parseInt(this.manager.find('.media-item').length);
        this.maxfile = parseInt(this.el.data('maxfile')) || (this.options.multiple ? false : 1);

        if (this.options.allowed) {
            this.uploader.find('input[name="fd-file"]').attr('accept', this.options.allowed.join(', '));
        }

        this.checkValidExtension = function(file, allowed) {
            return (new RegExp('(' + allowed.join('|').replace(/\./g, '\\.') + ')$', 'i')).test(file);
        };

        this.zone.event('send', function (files) {

            that.messagebox && that.messagebox.empty();

            files.each(function (file) {

                that.totalfile = parseInt(that.manager.find('.media-item').length);

                // Implement maximum files
                if (that.maxfile !== false && that.maxfile <= that.totalfile) {
                    that.messagebox && that.messagebox.append('<div class="alert alert-danger">Maximum uploaded file reached.</div>');
                    return false;
                }

                var chunkNumber = Math.ceil(file.size / that.chunkSize),
                    fileTotal = file.size;

                // Implement allowed file
                if (!that.checkValidExtension(file.name, that.options.allowed)) {
                    that.messagebox && that.messagebox.append('<div class="alert alert-danger">File ' + file.name + ' type is not allowed to be uploaded.</div>');
                    return;
                }

                // Check for maxsize
                if (file.size > that.options.maxsize) {
                    that.messagebox && that.messagebox.append('<div class="alert alert-danger">File is too large, cannot upload ' + file.name + '</div>');
                    return;
                }

                // Chunked files
                if (chunkNumber > 1) {

                    var i = 0; Queue = $({}), x=1;
                    for (x=1; x <= chunkNumber; x++) {

                        Queue.queue('upload-chunks', function(next) {
                            var start = i * that.chunkSize,
                                end = (start + that.chunkSize);

                            file.read({
                                start: start,
                                end: (end > file.size) ? file.size : end,
                                func: 'bin',
                                onDone: function(data) {
                                    setTimeout(function() {
                                        file.sendChunkedTo(that.url, {
                                            method: 'POST',
                                            token: $('[name="_token"]').val(),
                                            chunk: i,
                                            chunks: chunkNumber,
                                            multiple: that.options.multiple,
                                            media: that.media
                                        }, data);
                                    }, 10)
                                },
                                onError: function(e) {

                                }
                            });

                            file.event('xhrSetup', function(xhr) {
                                if (i == 0) {
                                    that.progressbar.parent().fadeIn();
                                    that.progressbar.css('width', '0%');
                                    that.currentbox.text('Uploading ' + this.name);
                                }
                            });

                            file.event('done', function(xhr) {
                                if (xhr.readyState == 4) {

                                    var responseText = xhr.responseText;

                                    // Force abort() to reset the xhr state
                                    xhr.abort();
                                    setTimeout(function () {
                                        i++;

                                        next();

                                        if (i == chunkNumber) {
                                            that.currentbox.text('Finished uploading ' + file.name);
                                            that.progressbar.parent().fadeOut();
                                            that.progressbar.css('width', '0%');

                                            if (that.manager.length) {
                                                that.manager.append(responseText);
                                                that.manager.find('.media-empty').hide();
                                                InitializeMediaRemover();
                                            }
                                        }
                                        else {
                                            that.currentbox.text('Finished uploading part ' + i + ' out of ' + chunkNumber);
                                        }
                                    }, 400);
                                }
                            });

                            file.event('progress', function (sent, total) {
                                that.progressbar.css('width', Math.round(((i * that.chunkSize) + sent) / fileTotal * 100) + '%');
                            });

                        });

                    }

                    Queue.dequeue('upload-chunks');
                }

                // Single files
                else {

                    file.event('xhrSetup', function(xhr) {
                        that.progressbar.parent().fadeIn();
                        that.progressbar.css('width', '0%');
                        that.currentbox.text('Uploading ' + this.name);
                    });

                    file.event('done', function (xhr) {
                        that.currentbox.text('Finished uploading ' + this.name);

                        if (that.manager.length) {
                            that.manager.append(xhr.responseText);
                            that.manager.find('.media-empty').hide();
                            InitializeMediaRemover();
                        }

                        that.progressbar.parent().fadeOut();
                        that.progressbar.css('width', '0%');
                        that.totalfile++;
                    });

                    file.event('progress', function (sent, total) {
                        that.progressbar.css('width', Math.round(sent / total * 100) + '%');
                    });

                    file.sendTo(that.url, {method: 'POST', token: $('[name="_token"]').val(), media: that.media, multiple: that.options.multiple});
                }
            });
        });

    };


    var InitializeMediaRemover = function() {
        $('[data-click-remove="true"]')
            .not('.initialized')
            .addClass('initialized')
            .confirmation({
                singleton: true,
                popout: true,
                onConfirm: function(event, element) {

                    var Element = $(element),
                        MediaManager = Element.closest('.media-manager')

                    $.ajax(Element.data('url'), {
                        method: 'POST',
                        data: {
                            media: Element.data('media'),
                            id: Element.data('file')
                        },
                        headers: {
                            'X-CSRF-TOKEN': $('[name="_token"]').val()
                        },
                        success: function(result) {
                            Element.parent().remove();
                            if (MediaManager.children().length == 1) {
                                MediaManager.find('.media-empty').show();
                            }
                        }
                    })

                }
            });


    };

    $(document)
        .on('click', '[data-click-remove="no-popup"]', function() {
            var Element = $(this),
                MediaManager = Element.closest('.media-manager');

            $.ajax(Element.data('url'), {
                method: 'POST',
                data: {
                    media: Element.data('media'),
                    id: Element.data('file')
                },
                headers: {
                    'X-CSRF-TOKEN': $('[name="_token"]').val()
                },
                success: function(result) {
                    Element.parent().remove();
                    if (MediaManager.children().length == 1) {
                        MediaManager.find('.media-empty').show();
                    }
                }
            })
        })
        .on('ready.initialize-mediauploader', function() {
            $('.media-uploader-box')
                .each(function() {
                    $(this)
                        .data('uploader-object', new VTUploader($(this)))
                        .addClass('initialized');
                });

            InitializeMediaRemover();

            $('.media-manager').each(function() {
                $(this).find('.media-empty').hide();
                if ($(this).children().length == 1) {
                    $(this).find('.media-empty').show();
                }
            });

        })
        .on('ajaxComplete.initialize-mediauploader', function() {
            $('.media-uploader-box')
                .not('.initialized')
                .each(function() {
                    $(this)
                        .data('uploader-object', new VTUploader($(this)))
                        .addClass('initialized');
                });

            InitializeMediaRemover();

            $('.media-manager').each(function() {
                $(this).find('.media-empty').hide();
                if ($(this).children().length == 1) {
                    $(this).find('.media-empty').show();
                }
            });
        });

})(jQuery);