(function ($) {

    "use strict";

    var SW = {
        document: $(document),
        window: $(window),
        videos: $('.video'),
        resizeVideo: function() {
            this.videos.find('iframe').each(function () {
                var width = $(this).width(), height = width / 16 * 9;
                $(this).attr('height', height);
            });
        },
        toggleCollapse: function(e, Source) {
            e.preventDefault();
            $(Source.data('target')).toggleClass('in');
        },
        loadVimeoThumbnail: function() {
            $('[data-vimeo-id]:not(.video-loaded)').each(function() {
                var self = $(this);
                self.css('opacity', 1);
                self.addClass('video-loaded');
                $.ajax({
                    type:'GET',
                    url: 'http://vimeo.com/api/v2/video/' + self.data('vimeo-id') + '.json',
                    jsonp: 'callback',
                    dataType: 'jsonp',
                    success: function(data){
                        if (data[0] && data[0].thumbnail_large) {
                            self.attr('src', data[0].thumbnail_large);
                            setTimeout(function() {
                                self.closest('.video-loader').removeClass('video-loader');
                            }, 5);
                        }
                    }
                });
            })
        }
    };

    SW.wwidth = SW.window.width();

    SW.document
        .on('ajaxComplete', function() {
            SW.loadVimeoThumbnail();
            SW.resizeVideo();
        })
        .on('ready.resizeVideo', SW.resizeVideo())
        .on('ready.loadVimeoThumb', SW.loadVimeoThumbnail())
        .on('click.toggleCollapse', '[data-toggle="collapse"]', function(e) {
            SW.toggleCollapse(e, $(this))
        });

    SW.window
        .on('resize', function() {
            if (SW.wwidth !== $(this).width()) {
                SW.resizeVideo();
                SW.wwidth = $(this).width();
            }
        });

})(jQuery);